<template>
  <b-row>
    <b-col md="12">
      <b-row>
        <b-col md="6">FN SKU:</b-col>
        <b-col md="6">{{ fbaProduct.fn_sku }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6">Seller SKU:</b-col>
        <b-col md="6">{{ fbaProduct.seller_sku }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6">Toplam Stok:</b-col>
        <b-col md="6">{{ fbaProduct.total_quantity }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6">Aktif Stok:</b-col>
        <b-col md="6">{{ fbaProduct.fulfillable_quantity }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6">Çalışan Stok:</b-col>
        <b-col md="6">{{ fbaProduct.inbound_working_quantity }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6">Kargoda Stok:</b-col>
        <b-col md="6">{{ fbaProduct.inbound_shipped_quantity }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6">Geri Gelen Stok:</b-col>
        <b-col md="6">{{ fbaProduct.inbound_receiving_quantity }}</b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>


export default {
  name: "StoreFbaProduct",
  props: {
    fbaProduct: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
