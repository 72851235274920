<template>
  <b-overlay :show="show" rounded="sm">
    <!-- supplierProduct.stock>0?'success':'secondary' -->
    <b-button :variant="getSupplierIds.includes(supplierProduct.supplier_id)?'danger':supplierProduct.stock>0?'success':'secondary'
" size="sm"
              @click="addPreOrder">
      {{ supplier_name(supplierProduct.supplier_id) }} - {{ supplierProduct.price }} ₺ - {{ supplierProduct.stock }}
    </b-button>
  </b-overlay>
</template>

<script>
import supplierMixins from '@/mixins/supplierMixins'

export default {
  name: "PreOrderAdd",
  mixins: [supplierMixins],
  props: {
    amount: {
      required: true,
      type: Number,
    },
    storeProduct: {
      required: true,
      type: Object,
    },
    storeProductId: {
      required: true,
      type: Number,
    },
    supplierProduct: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    show: false,
  }),
  computed: {
    getSupplierIds() {
      let ids = [];
      this.storeProduct.pre_orders.forEach(preOrder => {
        ids.push(preOrder.supplier_id);
      })
      return ids;
    }
  },
  methods: {
    addPreOrder() {
      this.show = true;
      this.$store.dispatch('azStoreProduct/addPreOrder', {
        az_store_product_id: this.storeProductId,
        product_id: this.supplierProduct.product_id,
        supplier_id: this.supplierProduct.supplier_id,
        amount: this.amount,
        price: this.supplierProduct.price
      }).then(res => {
        this.show = false;
        this.$swal({
          title: res.message,
          icon: res.status,
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
