<template>
  <b-row>
    <b-col md="2">
      <b-badge
          v-if="preOrder.pre_order_status_id"
          :variant="preOrder.status.color"
          @dblclick="changeStatus"
      >
        {{ preOrder.status.name }}
      </b-badge>

      <v-select
          v-else
          label="name"
          :options="_preOrderStatuses"
          :reduce="item => item.id"
          class="select-size-sm"
          placeholder="Ön Sipariş Statüsü"
          @option:selected="selectPreOrderStatus"
      />

    </b-col>
    <b-col md="2">
      <b-badge v-if="preOrder.user"> {{ preOrder.user.short_name }}</b-badge>

    </b-col>
    <b-col md="2">
      <b-badge
          v-if="preOrder.supplier_id"
          @dblclick="changeSupplier"
      >
        {{ preOrder.supplier.name }}
      </b-badge>

      <v-select
          v-else
          label="name"
          :options="_suppliers"
          :reduce="item => item.id"
          class="select-size-sm"
          placeholder="Tedarikçi"
          @option:selected="selectPreOrderSupplier"
      />

    </b-col>
    <b-col md="3">
      <b-form-input type="number" size="sm" v-model="preOrder.amount"
                    placeholder="Miktar" disabled/>
    </b-col>
    <b-col md="3">
      <b-form-input type="number" size="sm" v-model="preOrder.price"
                    placeholder="Fiyat" disabled/>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";

export default {
  name: "PreOrderList",
  components: {vSelect},
  props: {
    productId: {
      required: true,
      type: Number,
    },
    preOrder: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters('preOrderStatus', ["_preOrderStatuses"]),
    ...mapGetters('supplier', ['_suppliers'])
  },
  methods: {
    changeStatus() {
      this.preOrder.pre_order_status_id = null;
    },
    changeSupplier() {
      this.preOrder.supplier_id = null;
    },
    selectPreOrderStatus(status) {

      this.$store.dispatch('preOrder/putPreOrder', {
        id: this.preOrder.id,
        pre_order_status_id: status.id
      })
          .then(res => {
            if (res.status == 'success') {
              this.preOrder.pre_order_status_id = status.id;
              this.preOrder.status = status
            }
          })

      /*
      axios.put('/preorder/' + this.preOrder.id, {pre_order_status_id: status.id})
          .then(res => {
            if (res.data.status == 'success') {
              this.preOrder.pre_order_status_id = status.id;
              this.preOrder.status = status
            }
          })
       */
    },
    selectPreOrderSupplier(supplier) {
      this.$store.dispatch('preOrder/putPreOrder', {
        id: this.preOrder.id,
        supplier_id: supplier.id
      })
          .then(res => {
            if (res.status == 'success') {
              this.preOrder.supplier_id = supplier.id;
              this.preOrder.supplier = supplier
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
