<template>

  <b-overlay :show="show" rounded="sm">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="11">
            <b-row>
              <b-col md="1" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_filter.product.id" placeholder="Ürün No"/>
                <b-form-input type="text" size="sm" v-model="_filter.product.barcode" placeholder="Ürün Barkod"/>
                <b-form-input type="text" size="sm" v-model="_filter.product.name" placeholder="Ürün Adı"/>
              </b-col>


              <b-col md="2">
                <b-row>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm" v-model="_filter.az_store_fba_products.total_quantity_min"
                        placeholder="Toplam Stok Min"/>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm"
                        v-model="_filter.az_store_fba_products.total_quantity_max"
                        placeholder="Taplam Stok Maks."/>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm" v-model="_filter.az_store_fba_products.fulfillable_quantity_min"
                        placeholder="fulfillable_quantity_min"/>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm"
                        v-model="_filter.az_store_fba_products.fulfillable_quantity_max"
                        placeholder="fulfillable_quantity_max"/>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm" v-model="_filter.az_store_fba_products.inbound_working_quantity_min"
                        placeholder="inbound_working_quantity_min"/>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm"
                        v-model="_filter.az_store_fba_products.inbound_working_quantity_max"
                        placeholder="inbound_working_quantity_max"/>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm" v-model="_filter.az_store_fba_products.inbound_shipped_quantity_min"
                        placeholder="inbound_shipped_quantity_min"/>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm"
                        v-model="_filter.az_store_fba_products.inbound_shipped_quantity_max"
                        placeholder="inbound_shipped_quantity_max"/>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm" v-model="_filter.az_store_fba_products.inbound_receiving_quantity_min"
                        placeholder="inbound_receiving_quantity_min"/>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <b-form-input
                        type="number"
                        size="sm"
                        v-model="_filter.az_store_fba_products.inbound_receiving_quantity_max"
                        placeholder="inbound_receiving_quantity_max"/>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="1" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_filter.azProduct.merchant_sku" placeholder="ASIN"/>
                <b-form-input type="text" size="sm" v-model="_filter.az_store_fba_products.fn_sku"
                              placeholder="FN SKU"/>
                <b-form-input type="text" size="sm" v-model="_filter.az_store_fba_products.seller_sku"
                              placeholder="FN Seller Sku"/>

                <b-form-group label-for="brand">
                  <v-select
                      v-model="_filter.product.brandIds"
                      :options="_brands"
                      label="name"
                      :reduce="item => item.id"
                      input-id="brand"
                      multiple
                      placeholder="Markalar"
                  />
                </b-form-group>

              </b-col>

              <b-col md="2">
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <b-col class="m-0 p-0">
                        <b-form-input type="number" size="sm" v-model="_filter.by_amazon.min"
                                      placeholder="AFN Satıcı Min"/>
                      </b-col>
                      <b-col class="m-0 p-0">
                        <b-form-input type="number" size="sm" v-model="_filter.by_amazon.max"
                                      placeholder="AFN Satıcı Maks"/>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <b-row>
                      <b-col class="m-0 p-0">
                        <b-form-input type="number" size="sm" v-model="_filter.by_merchant.min"
                                      placeholder="MFN Satıcı Min"/>
                      </b-col>
                      <b-col class="m-0 p-0">
                        <b-form-input type="number" size="sm" v-model="_filter.by_merchant.max"
                                      placeholder="MFN Satıcı Maks"/>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <b-row>
                      <b-col class="m-0 p-0">
                        <b-form-input type="number" size="sm" v-model="_filter.sales_ranks.rank.min"
                                      placeholder="Rank Min"/>
                      </b-col>
                      <b-col class="m-0 p-0">
                        <b-form-input type="number" size="sm" v-model="_filter.sales_ranks.rank.max"
                                      placeholder="Rank Maks"/>
                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>
              </b-col>

              <b-col md="3">
                <b-row>
                  <b-col md="12">
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_filter.amazon_fba"
                        inline
                        name="amazon_fba"
                        value=""
                    > AMZ Tümü
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_filter.amazon_fba"
                        inline
                        name="amazon_fba"
                        value="amazon"
                    > Var
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_filter.amazon_fba"
                        inline
                        name="amazon_fba"
                        value="notamazon"
                    > Yok
                    </b-form-radio>
                  </b-col>
                  <b-col md="12">
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_filter.az_store_fba_products.is_fba"
                        inline
                        name="fba"
                        value=""
                    > FBA Biz Tümü
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_filter.az_store_fba_products.is_fba"
                        inline
                        name="fba"
                        value="fba"
                    > Var
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_filter.az_store_fba_products.is_fba"
                        inline
                        name="fba"
                        value="notfba"
                    > Yok
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="2">
                <v-select
                    v-model="_filter.preOrder.statuses"
                    label="name"
                    multiple
                    :options="_preOrderStatuses"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Ön Sipariş Statüsü"
                    :closeOnSelect="false"
                />

                <v-select
                    v-model="_filter.supplier.ids"
                    label="name"
                    multiple
                    :options="_suppliers"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Tedarikçi"
                    :closeOnSelect="false"
                />
                <b-form-input type="number" size="sm" v-model="_filter.supplier.stock"
                              placeholder="Min Tedarikçi Stoğu"/>
                <b-form-group>
                  <flat-pickr
                      v-model="_filter.buybox_list.scan_date"
                      class="form-control form-control-sm"
                      placeholder="Tarama Tarihi"
                  />
                </b-form-group>


              </b-col>

              <b-col md="1" class="m-0 p-0">
                <v-select
                    v-model="_filter.store_ids"
                    label="store_name"
                    multiple
                    :options="getStores"
                    :reduce="store => store.id"
                    class="select-size-sm"
                    placeholder="Pazaryeri"
                    :closeOnSelect="false"
                />
                <v-select
                    v-model="_filter.limit"
                    :options="[1,5,10,20,50,100,250,500,1000,2500,10000]"
                    class="select-size-sm"
                    placeholer="Limit"
                />

                <v-select
                    v-model="_filter.sorting"
                    label="key"
                    :options="sortingList"
                    :reduce="item => item.val"
                    class="select-size-sm"
                    placeholder="Sıralama"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button-group size="sm">
              <b-button variant="primary" @click="getFilter(1)">Getir</b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <Pagination :pagination="_pagination" @pageClick="getFilter"/>
      </b-form>
    </b-card>
  </b-overlay>

</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";
import Pagination from "@/components/Pagination.vue";
import flatPickr from 'vue-flatpickr-component'

export default {
  name: "StoreProductFilter",
  components: {vSelect, Pagination, flatPickr},
  data: () => ({
    show: false,
    sortingList: [
      {key: 'A-Z', val: 'ASC'},
      {key: 'Z-A', val: 'DESC'},
    ],
  }),
  computed: {
    ...mapGetters('azStoreProduct', ["_filter", "_pagination"]),
    ...mapGetters('store', ['_stores']),
    ...mapGetters("supplier", ["_suppliers"]),
    ...mapGetters('preOrderStatus', ["_preOrderStatuses"]),
    ...mapGetters('productBrand', ['_brands']),
    getStores() {
      return this._stores.filter(store => store.marketplace_code == 'AZ')
    }
  },
  methods: {
    getFilter(page) {
      this.show = true;
      this._filter.page = page;
      this.$store.dispatch('azStoreProduct/getFilter')
          .then(res => {
            this.show = false;
          })
    }
  },
  mounted() {
    this.$store.dispatch('preOrderStatus/getStatus');
    this.$store.dispatch('productBrand/getAll');
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
