<template>
  <div>
    <StoreProductFilter/>
    <StoreProductTitle/>
    <PreOrderSearch/>
    <StoreProductList v-for="storeProduct in _searchProducts" :storeProduct="storeProduct" :key="storeProduct.id"/>
  </div>

</template>

<script>

import {mapGetters} from "vuex";
import StoreProductFilter from "@/views/amazon/store-product/StoreProductFilter.vue";
import StoreProductList from "@/views/amazon/store-product/StoreProductList.vue";
import StoreProductTitle from "@/views/amazon/store-product/StoreProductTitle.vue";
import PreOrderSearch from "@/views/amazon/store-product/PreOrderSearch.vue";
import vSelect from "vue-select";


export default {
  name: "StoreProductHome",
  components: {vSelect, StoreProductFilter, StoreProductTitle, StoreProductList, PreOrderSearch},
  computed: {
    ...mapGetters('azStoreProduct', ["_searchProducts", "_products", "_selecetedProducts"]),
  },
  methods: {}
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
