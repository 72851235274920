<template>
  <b-card>
    <b-row class="border border-success mb-1">
      <b-col md="2">
        {{ storeProduct.id }} <br>
        {{ storeProduct.store.store_name }}({{ storeProduct.store.marketplace_name }})
        <hr>
        Ön Sipariş :
        <b-badge>{{ storeProduct.pre_orders.length }}</b-badge>

      </b-col>
      <b-col md="3">
        <h5>
          <a :href="storeProduct.store.params.baseURL+'/gp/product/'+storeProduct.az_product.merchant_sku"
             target="_blank">
            <feather-icon icon="ExternalLinkIcon"/>
          </a> {{ storeProduct.az_product.product_name }}
        </h5>
        Asın: {{ storeProduct.az_product.merchant_sku }}
        <hr>
        Son Tarama: {{ scandate }}

      </b-col>
      <b-col md="2">
        <b-row>
          <b-col md="12">
            <b-row class="bg-info">
              <b-col md="4">Gönderen</b-col>
              <b-col md="4">Adet</b-col>
              <b-col md="4">Fiyat</b-col>
            </b-row>

            <b-row>
              <b-col md="4">Amazom:</b-col>
              <b-col md="4">{{ storeProduct.amazon_fba }}</b-col>
              <b-col md="4">{{ storeProduct.amazon_price }} ₺</b-col>
            </b-row>

            <b-row>
              <b-col md="4">AFN:</b-col>
              <b-col md="4">{{ storeProduct.by_amazon }}</b-col>
              <b-col md="4">{{ storeProduct.by_amazon_low_price }} ₺</b-col>
            </b-row>

            <b-row>
              <b-col md="4">MFN:</b-col>
              <b-col md="4">{{ storeProduct.by_merchant }}</b-col>
              <b-col md="4">{{ storeProduct.by_merchant_low_price }} ₺</b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-row class="bg-info">
              <b-col md="4">Min (₺)</b-col>
              <b-col md="4">Canlı (₺)</b-col>
              <b-col md="4">Maks (₺)</b-col>
            </b-row>
            <b-row>
              <b-col md="4"> {{ storeProduct.min_price }}</b-col>
              <b-col md="4"> {{ storeProduct.send_price }}</b-col>
              <b-col md="4"> {{ storeProduct.max_price }}</b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12"> Buybox Sıra:
            <b-badge :variant="storeProduct.buybox==1?'success':''">{{ storeProduct.buybox }} /
              {{ storeProduct.buyboxes }}
            </b-badge>
          </b-col>
        </b-row>

      </b-col>
      <b-col md="2">
        <div>
          <b-row class="bg-success">
            <b-col md="9">Kategori</b-col>
            <b-col md="3">Rank</b-col>
          </b-row>
        </div>
        <StoreProductSalesRank
            v-for="salesRank in storeProduct.sales_ranks"
            :key="salesRank.id"
            :sales-rank="salesRank"/>
      </b-col>
      <b-col md="2" v-if="storeProduct.az_product.az_store_fba_products.length>0">
        <b-row class="bg-warning">
          <b-col md="6">Data</b-col>
          <b-col md="6">Değer</b-col>
        </b-row>
        <StoreFbaProduct
            v-if="storeProduct.az_product.az_store_fba_products"
            v-for="fbaProduct in storeProduct.az_product.az_store_fba_products"
            :fba-product="fbaProduct"
            :key="fbaProduct.id"
        />
      </b-col>

      <div class="col-md-2" v-else>
        <div class="row bg-warning">
          <div class="col-md-6">Data</div>
          <div class="col-md-6">Değer</div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">FN SKU:</div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">Seller SKU:</div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">Toplam Stok:</div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">Aktif Stok:</div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">Çalışan Stok:</div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">Kargoda Stok:</div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">Geri Gelen Stok:</div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <b-col>
        <ProductList
            v-for="product in storeProduct.az_product.products"
            :key="product.id"
            :product="product"
            :store-product="storeProduct"
        />
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import StoreProductSalesRank from "@/views/amazon/store-product/StoreProductSalesRank.vue";
import StoreFbaProduct from "@/views/amazon/store-product/StoreFbaProduct.vue";
import ProductList from "@/views/amazon/store-product/ProductList.vue";

export default {
  name: "StoreProductList",
  components: {StoreProductSalesRank, StoreFbaProduct, ProductList},
  props: {
    storeProduct: {
      type: Object,
      required: true
    }
  },
  computed: {
    scandate() {
      return this.storeProduct.buybox_list?.scan_date;
    }
  },
}
</script>

<style lang="scss">

</style>
