<template>
  <b-card>
    <b-row>
      <b-col md="2">
        Pazaryeri
        <hr class="m-0 p-0">
        Resim
      </b-col>
      <b-col md="3">
        Amazon Ürün
        <hr class="m-0 p-0">
        Bizim Ürün
      </b-col>
      <b-col md="2"> Fiyat</b-col>
      <b-col md="2"> Rank Değerleri</b-col>
      <b-col md="2"> FN Bilgileri</b-col>
    </b-row>
  </b-card>
</template>

<script>


export default {
  name: "StoreProductTitle"
}
</script>

<style scoped>

</style>
