<template>
  <b-row>

    <b-col md="1">
      <div class="imageContainer m-0 p-0" v-if="product.images[0]">
        <b-img
            class="border border-primary"
            :src="'https://api.kitapfirsatlari.com/' + product.images[0].url"
            width="150"
        />
      </div>

    </b-col>

    <b-col md="5">
      <b-row>
        <b-col md="12">
          <b-form-checkbox
              v-model="$store.state.azStoreProduct.selecetedProducts"
              :value="product.id"> Seç
          </b-form-checkbox>
        </b-col>

        <b-col md="3" class="m-0">
          <b-button variant="success" size="sm" @click="addPreOrder">Ön Sipariş Oluştur</b-button>
        </b-col>
        <b-col md="3" class="m-0">
          <b-form-input type="number" size="sm" v-model="amount" placeholder="Miktar"/>
        </b-col>
        <b-col md="3" class="m-0">
          <b-form-input type="number" size="sm" v-model="price" placeholder="Fiyat"/>
        </b-col>

        <b-col md="3" class="m-0">
          <v-select
              v-model="supplier_id"
              label="name"
              :options="_suppliers"
              :reduce="item => item.id"
              class="select-size-sm"
              placeholder="Tedarikçi"
              :closeOnSelect="true"
          />
        </b-col>

      </b-row>
      <hr>
      <div>
        <PreOrderList
            v-for="preOrder in storeProduct.pre_orders"
            v-if="preOrder.product_id==product.id"
            :pre-order="preOrder"
            :product-id="product.id"
            :key="preOrder.id"
        />
      </div>
    </b-col>

    <b-col md="6" class="border">
      <b>{{ product.name }}</b><br>
      <b-badge v-if="product.brand"> {{ product.brand.name }}</b-badge>
      <br>
      Barkod: <b> {{ product.barcode }} ({{ product.id }}})</b> <br>
      <div class="mt-2">
        <OrderProductSupplierProduct :product="product" :product-accounting="{}"/>

        <b-row>
          <b-col md="2">
            <b-form-input type="number" size="sm" placeholder="Miktar" v-model="amount2"/>
          </b-col>
          <b-col>
            <b-button-group>
              <PreOrderAdd
                  v-for="supplierProduct in product.supplier_products"
                  :supplier-product="supplierProduct"
                  :store-product-id="storeProduct.id"
                  :store-product="storeProduct"
                  :amount="amount2"
                  :key="supplierProduct.id"/>
            </b-button-group>
          </b-col>
        </b-row>


      </div>
    </b-col>

  </b-row>
</template>

<script>
import OrderProductSupplierProduct from '@/views/order/list/OrderProductSupplierProduct'
import PreOrderList from "@/views/amazon/store-product/PreOrderList.vue";
import PreOrderAdd from "@/views/amazon/store-product/PreOrderAdd.vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "ProductList",
  components: {vSelect, OrderProductSupplierProduct, PreOrderList, PreOrderAdd},
  props: {
    storeProduct: {
      required: true,
      type: Object,
    },
    product: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    amount: 1,
    amount2: 1,
    supplier_id: null,
    price: null,
  }),
  computed: {
    ...mapGetters("supplier", ["_suppliers"]),
    ...mapGetters('azStoreProduct', ["_selecetedProducts"]),
  },
  methods: {
    addPreOrder() {
      this.$store.dispatch('azStoreProduct/addPreOrder', {
        az_store_product_id: this.storeProduct.id,
        product_id: this.product.id,
        amount: this.amount,
        price: this.price,
        supplier_id: this.supplier_id,
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
