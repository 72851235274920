<template>
  <b-card>
    <b-row>
      <b-col md="1">

        <b-form-checkbox
            v-model="isAllSelected"
            :disabled="_searchProducts.length<1"
            class=" custom-control-secondary"
            @change="selectAllSearchProduct"
        />
        {{ _selecetedProducts.length }} / {{ _searchProducts.length }}
      </b-col>

      <b-col md="1">
        <b-form-checkbox v-model="_search.single"> Tekli</b-form-checkbox>
        <b-form-checkbox v-model="_search.multiple"> Çoklu</b-form-checkbox>
      </b-col>

      <b-col md="1">
        <b-button variant="success" :disabled="_selecetedProducts.length<1" size="sm" @click="createPreOrder">
          Ön Sipariş Oluştur
        </b-button>
      </b-col>

      <b-col md="1">
        <b-form-input type="number" :disabled="_selecetedProducts.length<1" size="sm" v-model="amount"
                      placeholder="Miktar"/>
      </b-col>

      <b-col md="2">
        <v-select
            :disabled="_selecetedProducts.length<1"
            v-model="supplier_id"
            label="name"
            :options="_suppliers"
            :reduce="item => item.id"
            class="select-size-sm"
            placeholder="Tedarikçi"
            :closeOnSelect="true"
        />
      </b-col>

      <b-col md="2">
        <v-select
            v-model="_search.statuses"
            label="name"
            multiple
            :options="_preOrderStatuses"
            :reduce="item => item.id"
            class="select-size-sm"
            placeholder="Ön Sipariş Statüsü"
            :closeOnSelect="false"
        />

      </b-col>
      <b-col md="1" class="m-0 p-0">
        <v-select
            v-model="_search.limit"
            :options="[1,5,10,20,50,100,250,500,1000,2500,10000]"
            class="select-size-sm"
            placeholer="Limit"
        />
      </b-col>

      <b-col md="1" class="m-0 p-0">
        <v-select
            v-model="_search.orderBy"
            label="key"
            :options="_search.sortingList"
            :reduce="item => item.val"
            class="select-size-sm"
            placeholder="Sıralama"
        />
      </b-col>

      <b-col md="1" class="m-0 p-0">
        <v-select
            v-model="_search.orderById"
            label="key"
            :options="_search.sortings"
            :reduce="item => item.val"
            class="select-size-sm"
            placeholder="Tedarikçi"
            :closeOnSelect="true"
        />
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";

export default {
  name: "PreOrderSearch",
  components: {vSelect},
  data: () => ({
    amount: 1,
    supplier_id: null,
  }),
  computed: {
    ...mapGetters("supplier", ["_suppliers"]),
    ...mapGetters('azStoreProduct', ["_searchProducts", "_selecetedProducts", "_search"]),
    ...mapGetters('preOrderStatus', ["_preOrderStatuses"]),
    isAllSelected: {
      get() {
        return false
      },
      set() {

      }
    }
  },
  methods: {
    createPreOrder() {
      this.$store.dispatch('azStoreProduct/addPreOrder', {
        az_store_product_id: this.storeProduct.id,
        product_id: this.product.id,
        amount: this.amount,
        price: this.price,
        supplier_id: this.supplier_id,
      })
    },
    selectAllSearchProduct(event) {
      let selected = []
      let isMultiple = 0;
      let isNull = 0;
      if (event) {
        this._searchProducts.forEach(sProduct => {
          let productCount = sProduct.az_product.products.length;
          if (productCount == 1) {
            sProduct.az_product.products.forEach(product => {
              selected.push(product.id)
            })
          } else if (productCount == 0) {
            isNull++;
          } else {
            isMultiple++;
          }
        })
      }
      console.log('Boş ' + isNull)
      console.log('Çoklu ' + isMultiple)
      this.$store.state.azStoreProduct.selecetedProducts = selected;
    }
  }
}
</script>

<style scoped>

</style>
