<template>
  <div>
    <b-row>
      <b-col v-if="salesRank.product_category.name" md="9">
        {{ salesRank.product_category.name }}
      </b-col>
      <b-col v-else md="9" @dblclick="setName">
        {{ salesRank.product_category.product_category_id }}
      </b-col>
      <b-col md="3">
        {{ salesRank.sales_rank }}
      </b-col>
    </b-row>
  </div>
</template>

<script>


export default {
  name: "StoreProductSalesRank",
  props: {
    salesRank: {
      type: Object,
      required: true,
    }
  },
  methods: {
    setName() {

    }
  }
}
</script>

<style scoped>

</style>
