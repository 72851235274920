import { render, staticRenderFns } from "./StoreFbaProduct.vue?vue&type=template&id=4fa6c382&scoped=true&"
import script from "./StoreFbaProduct.vue?vue&type=script&lang=js&"
export * from "./StoreFbaProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa6c382",
  null
  
)

export default component.exports